import React from 'react'
import HomeNavBar from '../Components/HomeNavBar'


const Home = () => {
  return (
    <div className='h-screen bg-[url("../Assets/HomePage.webp")] bg-cover '>
      <HomeNavBar />

      <div className='mx-auto flex-row justify-center items-center text-center h-auto w-2/3 m-32'>
        <h1 className='filter drop-shadow-2xl font-montserrat text-4xl font-bold text-white sm:text-7xl'>Precision grooming for the modern man.</h1>
        <p className='filter drop-shadow-2xl backdrop-brightness-90 backdrop-blur-sm text-gray-400 my-4 text-lg'>Indulge in a transformative experience with our skilled barbers. Elevate your style, boost confidence, and leave with a look that speaks volumes. Your next great haircut awaits!</p>
        <div className='my-7 lg:my-24  '>
          <a href="https://www.fresha.com/a/upgrade-barbershop-dby-upgrade-barbershop-jvc-dubai-jumeirah-village-circle-lja7xo4e/booking?menu=true" class="relative inline-flex items-center justify-center px-6 py-3 overflow-hidden font-bold text-white rounded-md shadow-2xl group">
            <span class="absolute inset-0 w-full h-full transition duration-300 ease-out opacity-0 bg-gradient-to-br from-pink-600 via-purple-700 to-blue-400 group-hover:opacity-100"></span>

            <span class="absolute top-0 left-0 w-full bg-gradient-to-b from-white to-transparent opacity-5 h-1/3"></span>

            <span class="absolute bottom-0 left-0 w-full h-1/3 bg-gradient-to-t from-white to-transparent opacity-5"></span>

            <span class="absolute bottom-0 left-0 w-4 h-full bg-gradient-to-r from-white to-transparent opacity-5"></span>

            <span class="absolute bottom-0 right-0 w-4 h-full bg-gradient-to-l from-white to-transparent opacity-5"></span>
            <span class="absolute inset-0 w-full h-full border border-white rounded-md opacity-10"></span>
            <span class="absolute w-0 h-0 transition-all duration-300 ease-out bg-white rounded-full group-hover:w-56 group-hover:h-56 opacity-5"></span>
            <span class="relative">Book An Appoinment</span>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Home